<template>
  <CModal
    :show="show"
    :no-cancel="!mapMode"
    :no-backdrop-close="mapMode"
    variant="formsheet"
    :content-class="mapMode ? 'flex flex-wrap' : ''"
    close-button
    @submit="onContinue"
    @close="$emit('close')"
  >
    <template v-slot:header>
      {{ $t('deliveryAreas') }}
    </template>
    <template v-slot:header-center>
      <CSegmentControl v-model="segment">
        <CSegmentControlItem :label="$t('map')" value="map" icon="map" />
        <CSegmentControlItem :label="$t('list')" value="list" icon="view-list" />
      </CSegmentControl>
    </template>
    <template v-if="mapMode" v-slot:header-right>
      <CLink class="text-gray-600" @click="$refs.map.mapHelp = true">
        {{ $t('help') }}
        <CIcon name="question-mark-circle" size="16" class="ml-1" />
      </CLink>
    </template>
    <template v-slot:content>
      <MapSelector
        v-show="mapMode"
        ref="map"
        :submit-loading="submitLoading"
        @delete="onDelete"
        @update="openUpdateModal"
        @submit-selected="createMultiple"
      />
      <div v-show="!mapMode" class="p-4 md:p-6 lg:p-8">
        <ListSelector
          @update="openUpdateModal"
          @open-create="createUpdateModal = true"
        />
      </div>
      <Portal to="overlays">
        <CreateUpdateModal
          v-model="createUpdateModal"
          :update-id="updateId"
          :update-data="updateData"
          @toggle="onUpdateModalToggle"
        />
      </Portal>
    </template>
    <!-- <template v-else v-slot:default>
      <ListSelector @update="openUpdateModal" />
      <Portal to="overlays">
        <CreateUpdateModal
          v-model="createUpdateModal"
          :update-id="updateId"
          :update-data="updateData"
          @toggle="onUpdateModalToggle"
        />
      </Portal>
    </template> -->
    <template v-slot:submit>
      <template v-if="mapMode">
        {{ $t('continue') }}
      </template>
      <template v-else>
        {{ $t('finished') }}
      </template>
    </template>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_DELIVERY_AREA, DELETE_DELIVERY_AREA } from '@/store/actionTypes';
import germanDecimalParser from '@/utils/germanDecimalParser';
import MapSelector from './MapSelector.vue';
import ListSelector from './ListSelector.vue';
import CreateUpdateModal from './CreateUpdateModal.vue';

export default {
  components: { MapSelector, ListSelector, CreateUpdateModal },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    segment: 'map',
    submitLoading: false,

    createUpdateModal: false,
    updateId: null,
    updateData: {
      postalCode: null,
      additionalPrice: null,
      location: {},
    },
  }),

  computed: {
    mapMode() {
      return this.segment === 'map';
    },
  },

  methods: {
    ...mapActions([CREATE_DELIVERY_AREA, DELETE_DELIVERY_AREA]),
    onDelete(id) {
      this.deleteDeliveryArea(id);
    },
    async createMultiple({ country, postalCodes, resetSelection }) {
      this.submitLoading = true;
      await Promise.all(
        postalCodes.map((postalCode) => {
          return this.createDeliveryArea({
            postalCodeId: `${country}-${postalCode}`,
            additionalPrice: 0,
          });
        }),
      );
      resetSelection(true);
      this.submitLoading = false;
    },
    openUpdateModal({ id, data }) {
      this.updateId = id;
      this.updateData.postalCode = data.postalCodeId;
      this.updateData.location = data.location;
      this.updateData.additionalPrice = germanDecimalParser(data.additionalPrice || 0, true);
      this.createUpdateModal = true;
    },
    onUpdateModalToggle(show) {
      if (!show) {
        setTimeout(() => {
          this.updateId = null;
          this.updateData = {
            postalCode: null,
            additionalPrice: null,
          };
        }, 300);
      }
    },
    onContinue() {
      if (this.mapMode) {
        this.segment = 'list';
      } else {
        this.$emit('close');
        setTimeout(() => {
          this.segment = 'map';
        }, 300);
      }
    },
  },
};
</script>

<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="mb-8">
    <div class="flex items-center justify-between mb-4">
      <CTypo tstyle="title3">{{ $t('currentOrders') }}</CTypo>
      <CLink to="orders">
        {{ $t('allOrders') }}
      </CLink>
    </div>
    <CTable
      v-if="!loading"
      :data="currentOrders"
      box
      mobile-cards
      mobile-breakpoint="1023px"
      v-bind="{ ...$attrs }"
      narrowed
      hoverable
      @page-change="onPageChange"
      @row-click="onRowClick"
    >
      <o-table-column :label="$t('orderedAt')" v-slot="{ row }" width="130" field="createdAt">
        {{ parseDate(row.createdAt) }}
      </o-table-column>
      <o-table-column :label="$t('customer')" v-slot="{ row }" field="customer.user.firstName">
        {{ row.customer.user.firstName }}
        {{ row.customer.user.lastName }}
      </o-table-column>
      <o-table-column
        :label="$t('merchantWastes.default')"
        v-slot="{ row }"
        field="containerOrder.merchantWaste.customName"
      >
        {{ row.containerOrder.merchantWaste.customName }}
      </o-table-column>
      <o-table-column
        :label="$t('container')"
        v-slot="{ row }"
        field="containerOrder.product.container.size"
      >
        {{ $n(row.containerOrder.product.container.size) }}m&sup3;
        {{ $t(`products.container.types.${row.containerOrder.product.container.type}`) }}
        <CContainerOpeningType
          :container="row.containerOrder.product.container"
          class="text-xs font-normal text-gray-700"
        />
      </o-table-column>
      <o-table-column
        :label="$t('deliveryDate')"
        sortable
        width="100"
        v-slot="{ row }"
        field="containerOrder.deliveryDate"
      >
        {{ parseDate(row.containerOrder.deliveryDate) }}
      </o-table-column>
      <o-table-column
        :label="$t('pickupDate')"
        sortable
        width="100"
        v-slot="{ row }"
        field="containerOrder.deliveryDate"
      >
        {{ parseDate(row.containerOrder.pickupDate) }}
      </o-table-column>
      <o-table-column
        :label="$t('address.deliveryAddress')"
        sortable
        v-slot="{ row }"
        field="deliveryAddress.postalCodeId"
      >
        {{ row.deliveryAddress.location.postalCode }} {{ row.deliveryAddress.location.city }}
      </o-table-column>
      <o-table-column
        :label="$t('netRevenue')"
        sortable
        v-slot="{ row }"
        field="containerOrder.totalPrice"
      >
        {{ $n(parsePrice(row.containerOrder.totalPrice), 'currency') }}
      </o-table-column>
      <o-table-column v-slot="{ row }" :label="$t('paymentStatus')" width="140" field="status">
        <PaymentStatus :status="row.status" small />
      </o-table-column>
    </CTable>
    <CLoading v-else class="py-6" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { toDecimalPrice } from '@contimo/api/src/utils/integerPriceParser';
import { GET_ORDERS } from '@/store/actionTypes';
import { CURRENT_ORDERS, ORDERS_NEXT_PAGE } from '@/store/gettersTypes';
import PaymentStatus from './PaymentStatus.vue';

export default {
  components: {
    PaymentStatus,
  },

  computed: {
    ...mapGetters([CURRENT_ORDERS, ORDERS_NEXT_PAGE]),
    loading() {
      return this.$store.state.orders.loading;
    },
  },

  created() {
    this.getOrders();
  },

  methods: {
    ...mapActions([GET_ORDERS]),
    parsePrice(price) {
      return toDecimalPrice(price, 19);
    },
    parseDate(date) {
      return new Date(date).toLocaleDateString();
    },
    onPageChange() {
      this.getOrders({ page: 1, perPage: 20 });
    },
    onRowClick(row) {
      this.$router.replace({
        query: {
          order: row.id,
        },
      });
    },
  },
};
</script>

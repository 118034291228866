<template>
  <section>
    <CInfoCardGroup>
      <CInfoCard large>
        {{ $t('onboarding.gettingStarted') }}
        <template v-slot:right>
          <CLink :href="helpLink" target="_blank" class="text-gray-600">
            {{ $t('help') }}
            <CIcon name="question-mark-circle" size="16" class="ml-1" />
          </CLink>
        </template>
        <template v-if="!thisMerchant" v-slot:description>
          {{ $t('onboarding.gettingStartedDescription') }}
        </template>
      </CInfoCard>
      <CInfoCard variant="success" icon="check-circle-outline" small>
        {{ $t('onboarding.emailVerified', { email: thisUser.email }) }}
      </CInfoCard>

      <CInfoCard
        v-for="card in shopOnboardingActionCards"
        :key="card.action"
        :variant="card.variant"
        :icon="card.icon"
        :small="card.variant === 'success'"
        :loading="card.loading"
      >
        {{ card.headline }}
        <template v-if="card.description" v-slot:description>
          {{ card.description }}
        </template>
        <template v-if="card.action !== 'goLive' && card.variant !== 'disabled'" v-slot:right>
          <CLink class="text-primary font-semibold" inverted @click="runAction(card.action)">
            {{ card.linkLabel || $t('start') }}
          </CLink>
        </template>
      </CInfoCard>
    </CInfoCardGroup>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ALL_DELIVERY_AREAS,
  ALL_MERCHANT_WASTES,
  CONTAINER_PRODUCTS,
  THIS_MERCHANT,
  THIS_USER,
} from '@/store/gettersTypes';
import { HELP } from '@/constants/links';
import {
  GET_DELIVERY_AREAS,
  GET_PRODUCTS,
  INIT_MERCHANT_WASTES,
  PLATFORM_ONBOARD_MERCHANT,
} from '@/store/actionTypes';

export default {
  data: () => ({
    onboardingLinkLoading: false,
    showSelectBusinessType: false,
    selectedBusinessType: 'company',
  }),

  computed: {
    ...mapGetters([
      THIS_USER,
      THIS_MERCHANT,
      ALL_DELIVERY_AREAS,
      ALL_MERCHANT_WASTES,
      CONTAINER_PRODUCTS,
    ]),
    helpLink: () => HELP,
    onboardingQuery() {
      return this.$route.query.onboarding;
    },
    shopOnboardingActionCards() {
      return [
        this.getCardData({
          successIndicator: this.allDeliveryAreas.length > 0,
          headline: this.$t('onboarding.deliveryAreas'),
          action: 'delivery',
          store: 'deliveryAreas',
        }),
        this.getCardData({
          successIndicator: this.allMerchantWastes.length > 0,
          headline: this.$t('onboarding.wasteTypesAndPricing'),
          action: 'wasteTypes',
          store: 'merchantWastes',
        }),
        this.getCardData({
          successIndicator: this.containerProducts.length > 0,
          headline: this.$t('onboarding.addContainer'),
          action: 'container',
          store: 'products',
        }),
      ];
    },
    merchantResourcesCounts() {
      return {
        deliveryAreas: this.allDeliveryAreas.length,
        merchantWastes: this.allMerchantWastes.length,
        products: this.containerProducts.length,
      };
    },
  },

  watch: {
    merchantResourcesCounts: {
      deep: true,
      handler(val) {
        if (
          !this.thisMerchant.platformOnboarded &&
          val.deliveryAreas &&
          val.merchantWastes &&
          val.products
        ) {
          this.platformOnboardMerchant();
        }
      },
    },
  },

  created() {
    if (this.onboardingQuery === 'return') {
      this.$router.replace({ query: { onboarding: undefined } });
    }
    this.getDeliveryAreas();
    this.initMerchantWastes();
    this.getProducts();
  },

  methods: {
    ...mapActions([
      GET_DELIVERY_AREAS,
      INIT_MERCHANT_WASTES,
      GET_PRODUCTS,
      PLATFORM_ONBOARD_MERCHANT,
    ]),
    runAction(action) {
      this.$emit('action', action);
    },
    getIcon(bool) {
      if (bool) return 'check-circle-outline';
      return 'dots-circle-horizontal-outline';
    },
    getCardData({ action, successIndicator, headline, store }) {
      return {
        icon: this.getIcon(successIndicator),
        variant: successIndicator ? 'success' : 'default',
        headline,
        action,
        linkLabel: successIndicator ? this.$t('update') : null,
        loading: this.$store.state[store]?.loading,
      };
    },
  },
};
</script>

<template>
  <Portal to="overlays">
    <DeliveryAreas :show="currentAction === 'delivery'" @close="currentAction = null" />
    <MerchantWastes :show="currentAction === 'wasteTypes'" @close="currentAction = null" />
    <ProductContainers :show="currentAction === 'container'" @close="currentAction = null" />
  </Portal>
</template>

<script>
import DeliveryAreas from './DeliveryAreas/OnboardingModal.vue';
import MerchantWastes from './MerchantWastes/OnboardingModal.vue';
import ProductContainers from './ProductContainers/OnboardingModal.vue';

export default {
  components: {
    DeliveryAreas,
    MerchantWastes,
    ProductContainers,
  },

  data: () => ({
    currentAction: null,
    currentActionLoading: false,
  }),

  methods: {
    open(action) {
      this.currentAction = action;
    },
  },
};
</script>

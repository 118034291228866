<template>
  <CModal
    :show="show"
    variant="formsheet"
    close-button
    @toggle="onToggle"
    @submit="onToggle"
  >
    <template v-slot:header>
      {{ $t('merchantWastes.manage') }}
    </template>
    <div class="min-h-[300px]">
      <GridSelector
        show-add-button
        @open-create="openModal"
        @open-update="openModal"
      />
    </div>
    <template v-slot:submit>
      {{ $t('finished') }}
    </template>
    <Portal to="overlays">
      <CreateUpdateModal v-model="showCreateUpdateModal" />
    </Portal>
  </CModal>
</template>

<script>
import merchantWasteFormStore from '@/store/dynamicModules/merchantWasteForm';
import GridSelector from './GridSelector.vue';
import CreateUpdateModal from './CreateUpdateModal.vue';

export default {
  components: {
    GridSelector,
    CreateUpdateModal,
  },

  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showCreateUpdateModal: false,
  }),

  created() {
    if (!this.$store.hasModule('merchantWasteForm')) {
      this.$store.registerModule('merchantWasteForm', merchantWasteFormStore);
    }
  },

  beforeDestroy() {
    if (!this.$store.hasModule('merchantWasteForm')) {
      this.$store.unregisterModule('merchantWasteForm', merchantWasteFormStore);
    }
  },

  methods: {
    onToggle(show) {
      this.$emit('toggle', show);
      if (!show) {
        this.$emit('close');
      }
    },
    openModal(data = null) {
      if (data === null) {
        this.$store.commit('merchantWasteForm/resetState');
        this.showCreateUpdateModal = true;
      } else {
        this.$store.commit('merchantWasteForm/setUpdateData', data);
        this.showCreateUpdateModal = true;
      }
    },
  },
};
</script>

<template>
  <CModal
    :show="show"
    variant="formsheet"
    close-button
    @toggle="onToggle"
    @submit="onToggle"
  >
    <template v-slot:header>
      {{ $t('products.manageContainer') }}
    </template>
    <template v-slot:header-right>
      <CButton
        variant="secondary"
        icon-left="plus-circle"
        rounded
        @click="createModal = true"
      >
        {{ $t('create') }}
      </CButton>
    </template>
    <template v-slot:content="{height}">
      <div class="h-[450px]">
        <TableList :max-height="height" :show-create-modal.sync="createModal" />
      </div>
    </template>
    <template v-slot:submit>
      {{ $t('finished') }}
    </template>
  </CModal>
</template>

<script>
import TableList from './TableList.vue';

export default {
  components: {
    TableList,
  },

  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    createModal: false,
  }),

  methods: {
    onToggle(show) {
      this.$emit('toggle', show);
      if (!show) {
        this.$emit('close');
      }
    },
  },
};
</script>

<template>
  <div
    class="inline-flex rounded-full px-2"
    :class="{
      'bg-green-500 text-green-50': status === 'paid',
      'bg-red-500 text-red-50': status === 'failed',
      'bg-yellow-500 text-yellow-50': status === 'pending' || status === 'init',
      'text-sm leading-6 font-medium': !small,
      'text-xs leading-5 font-semibold': small,
    }"
  >
    {{ $t(`paymentStatusTypes.${status}`) }}
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
